class wsClient{
    static reconectionTimeout = 5 * 1000;

    constructor({ onOpen, onClose, onMessage, onError}) {
        this.onOpen = onOpen;
        this.onClose = onClose;
        this.onMessage = onMessage;
        this.onError = onError;
    }

    Connect(address) {
        const {onOpen, onClose, onMessage, onError } = this;

        this.connection = new WebSocket(address);
        this.connection.connectionStage = wsClient.connectionStage.connecting;

        this.connection.onopen = (e) => {
            if (onOpen) onOpen(e);
            this.connection.connectionStage = wsClient.connectionStage.connected;
        };

        this.connection.onmessage = (e) => {
            if (onMessage) onMessage(JSON.parse (e.data));
        };
        this.connection.onclose = (e) => {
            this.connection.connectionStage = wsClient.connectionStage.disconnected;
            if (onClose) onClose(e);
        };
        this.connection.onerror = (e) => {
            console.error(e);
            if (onError) onError(e);
        };
    }

    SendJSON(json) {
        const connection = this.connection;
        if (!connection || connection.connectionStage != wsClient.connectionStage.connected) return;
        connection.send(JSON.stringify (json));
    }

    static connectionStage = {
        disconnected: 0,
        connecting: 1,
        connected: 2
    }
}

module.exports = {
    wsClient
}