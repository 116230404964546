export class wsMessage {
    m;
    d;

    constructor(messageType, data) {
        this.m = messageType;
        this.d = data;
    }

    static playerDataMsg(playersData) {
        return new wsMessage(wsMessage.messageType.PlayerData, playersData);
    }

    static pingMsg() {
        return new wsMessage(wsMessage.messageType.Ping);
    }

    static authenticationMsg(clientId) {
        return new wsMessage(wsMessage.messageType.Authentication, clientId);
    }

    static terminateMsg() {
        return new wsMessage(wsMessage.messageType.Terminate);
    }

    static triggerEmojiMsg(emoji) {
        return new wsMessage(wsMessage.messageType.Emoji, emoji);
    }

    static messageType = {
        Authentication: "authentication",
        PlayerData: "player_data",
        Ping: "ping",
        Update: "update",
        Terminate: "terminate",
        PlayerDisconnected: "player_disconnected",
        PlayerConnected: "player_connected",
        Countdown: "countdown",
        Emoji: "emoji",
    }
}