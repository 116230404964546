export class AudioManager {
    static isMuted = false;

    static audiofilesPath = "assets/sounds";
    static currentAmience = null;
    static currentTrack = null;
    static qaVideo = null;
    static qaVideoControl = null;
    static isQAVideoIsRunning = false;
    static sounds = {
        buttonPress: 'ID1_ButtonPress.mp3',
        portal: 'ID2_Portal.mp3',
        modalAppear: 'ID3_ModalAppear.mp3',
        cityAmbience: 'ID4_CityAmbience.mp3',
    }

    static Mute(callback) {
        AudioManager.isMuted = !AudioManager.isMuted;
        callback(AudioManager.isMuted);

        if (AudioManager.currentAmience != null) AudioManager.MuteAudio(AudioManager.currentAmience, AudioManager.isQAVideoIsRunning == true? true: AudioManager.isMuted);
        if (AudioManager.currentTrack != null) AudioManager.MuteAudio(AudioManager.currentTrack, AudioManager.isQAVideoIsRunning == true ? true : AudioManager.isMuted);
        if (AudioManager.qaVideo != null) AudioManager.qaVideo.muted = AudioManager.isMuted;
    }

    static MuteAudio(audio, state = true) {
        if (state) {
            audio.muted = state;
            audio.pause();
        } else {
            audio.muted = state;
            audio.play();
        }
    }

    static PlaySound(sound, isLoop = false, volume = 1) {
        const player = new Audio(`${AudioManager.audiofilesPath}/${sound}`);
        player.loop = isLoop;
        player.volume = volume;
        if (isLoop) {
            if (volume == 0.25) {
                AudioManager.currentAmience = player;
            } else {
                AudioManager.currentTrack = player;
            }
        }
        if (!AudioManager.isMuted) {
            player.play();
        }
    }

    static StopCurrentAmbience() {
        if (AudioManager.currentAmience != null) {
            AudioManager.currentAmience.pause();
            AudioManager.currentAmience.currentTime = 0;
            AudioManager.currentAmience = null;
        }
        if (AudioManager.currentTrack != null) {
            AudioManager.currentTrack.pause();
            AudioManager.currentTrack.currentTime = 0;
            AudioManager.currentTrack = null;
        }
        if (AudioManager.qaVideo != null) {
            AudioManager.qaVideo.pause();
            AudioManager.qaVideo.currentTime = 0;
            AudioManager.qaVideo = null;
            AudioManager.isQAVideoIsRunning = false;
        }
    }

    static PlayVideo(videoSrc, controlBtn, callback) {
        const video = document.createElement("video");
        video.setAttribute("src", videoSrc);
        video.addEventListener("loadedmetadata", function () {
            video.muted = AudioManager.isMuted;
            video.playsInline = true;
            video.loop = true;
            AudioManager.qaVideo = video;
            AudioManager.qaVideoControl = controlBtn;
            AudioManager.qaVideoControl.play.visible = true;
            AudioManager.qaVideoControl.pause.visible = false;
            callback(video);
        }, false);
    }

    static PlayQAVideo(state) {
        if (AudioManager.qaVideo == null) return;
        if (state && AudioManager.isQAVideoIsRunning) return;

        if (state == undefined) {
            state = !AudioManager.isQAVideoIsRunning;
        }

        if (state) {
            AudioManager.qaVideoControl.play.visible = false;
            AudioManager.qaVideoControl.pause.visible = true;
            AudioManager.qaVideo.play();
            AudioManager.isQAVideoIsRunning = true;

            if (AudioManager.currentAmience != null) AudioManager.MuteAudio(AudioManager.currentAmience, true);
            if (AudioManager.currentTrack != null) AudioManager.MuteAudio(AudioManager.currentTrack, true);

        } else {
            AudioManager.qaVideoControl.play.visible = true;
            AudioManager.qaVideoControl.pause.visible = false;
            AudioManager.qaVideo.pause();
            AudioManager.isQAVideoIsRunning = false;

            if (AudioManager.currentAmience != null) AudioManager.MuteAudio(AudioManager.currentAmience, AudioManager.isMuted == true? true :false);
            if (AudioManager.currentTrack != null) AudioManager.MuteAudio(AudioManager.currentTrack, AudioManager.isMuted == true ? true : false);
        }

    }
}