import { EventEmitter } from 'events';

export class AnalyticsEvents extends EventEmitter {

    constructor() {
        super();
        if (!AnalyticsEvents.instance) {
            AnalyticsEvents.instance = this;
        }
        return AnalyticsEvents.instance;
    }

    InitializeMetalitixLogger(apiKey, camera, scene) {
        this.logger = new window.MetalitixLogger(apiKey, {
            showSurvey: false,
            inactivityInterval: 360000
        });
        this.logger.startSession(camera, scene);
        console.log("Log session started")
    }

    LogStartExperience(event) {
        switch (event) {
            case "watchlandingvideo":
                this.logger.logCustomEvent('Watch landing video', {startexperience:'Watch landing video', watchlandingvideo: 1})
                break;
            case "next1":
                this.logger.logCustomEvent('Next 1', {startexperience:'Next1', next1: 1});
                break;
            case "next2":
                this.logger.logCustomEvent('Next 2', {startexperience:'Next2', next2: 1});
                break;
            case "avatarnext":
                this.logger.logCustomEvent('Avatar Next', {startexperience:'Avatarnext', avatarnext: 1})
                break;
        }
        console.log("Log " + event)
    }

    LogAvatarSelection(avatarIndex) {
        this.selectedAvatar = "avatar" + avatarIndex;
        this.logger.setCustomField('Avatar', this.selectedAvatar);
        this.LogGTMEvent('selected_avatar','avatarid',this.selectedAvatar)

        console.log("Log selected avatar: " + this.selectedAvatar);
    }

    LogStartInstructions(event) {
        switch (event) {
            case "instructionsnext1":
                this.logger.logCustomEvent('Instructions next 1', {startinstructions:'Instructions next 1', instructionsnext1: 1})
                break;
            case "instructionsnext2":
                this.logger.logCustomEvent('Instructions next 2', {startinstructions:'Instructions next 2', instructionsnext2: 1})
                break;
            case "letsgo":
                this.logger.logCustomEvent('Lets go', {startinstructions:'Lets go', letsgo: 1})
                break;
        }
        console.log("Log " + event)
    }

    LogCharactersNotSeen() {
        this.logger.setCustomField('sawcharacteraxe', false)
        this.logger.setCustomField('sawcharacterthebiter', false)
        this.logger.setCustomField('sawcharacterlilbaby', false)

        console.log("Log sawcharacters false")
    }

    LogCharacterSeen(rawname) {
        //The character name is the first part of rawname split by "_"
        const characterName = rawname.split("_")[0];
        const characterSeenString = 'sawcharacter' + characterName;
        this.logger.setCustomField(characterSeenString, true) 
        this.logger.logCustomEvent('Saw character ' + characterName, { sawcharacter: characterName })
        this.LogGTMEvent('saw_character_' + characterName ,characterSeenString,true)

        console.log("Log sawcharacter " + characterName + " = true")
    }

    LogQAMovieNotSeen() {
        this.logger.setCustomField('sawloopedqavideo', false)

        console.log("Log sawloopedq&avideo false")
    }

    LogQAMovieSeen() {
        this.logger.setCustomField('sawloopedqavideo', true)
        this.logger.logCustomEvent('Saw looped Q&A video', { sawloopedqavideo: 1 })
        this.LogGTMEvent('saw_loopQA_video','sawloopedqavideo',true)

        console.log("Log sawloopedqavideo = true")
    }

    LogArtworkPanelsNotSeen() {
        this.logger.setCustomField('sawartwork1', false)
        this.logger.setCustomField('sawartwork2', false)
        this.logger.setCustomField('sawartwork3', false)
        this.logger.setCustomField('sawartwork4', false)
        this.logger.setCustomField('sawartwork5', false)

        console.log("Log sawartworks false")
    }

    LogArtworkSeen(artworkIndex) {
        const artworkSeenString = 'sawartwork' + artworkIndex;
        this.logger.setCustomField(artworkSeenString, true)
        this.logger.logCustomEvent('Saw artwork ' + artworkIndex, { sawartworkamount: 1 })
        this.LogGTMEvent('saw_artwork_' + artworkIndex,artworkSeenString,true)

        console.log("Log sawartwork" + artworkIndex + " = true")
    }

    LogUsedPortal(){
        this.logger.logCustomEvent('Used Portal', { usedportal: 1 })
        this.LogGTMEvent('used_portal','usedportal',true)

        console.log("Log Portal Used")
    }

    LogWentBackOutside(){
        this.logger.logCustomEvent('Went back outside', { wentbackoutside: 1 })
        this.LogGTMEvent('went_back_outside','wentbackoutside',true)

        console.log("Log Went back outside")
    }

    LogGeneralEvent(event) {
        console.log("Log  " + event + " event")
        switch (event) {
            case "avatar communicated":
                this.logger.logCustomEvent('avatar communicated', { generalevent: 'avatar communicated', avatarcommunicated: 1 })
                this.LogGTMEvent('avatar_communicated')
                break;
        }
    }

    LogCTA(event) {
        console.log("Log cta: " + event)
        switch (event) {
            case "Share":
                this.logger.logCustomEvent('Share pressed', { calltoaction: 'Share', share: 1 })
                this.LogGTMEvent('share_pressed')
                break;
            case "Download":
                this.logger.logCustomEvent('Download pressed', { calltoaction: 'download', download: 1 })
                this.LogGTMEvent('download_pressed')
                break;
        }
    }

    LogCommunicate(event) {
        console.log("Log communicate: " + event)
        switch (event) {
            case "blue_icon":
                this.logger.logCustomEvent('Sent Blue Icon emoji', { communicate: 'Blue Icon', blueicon: 1 })
                this.LogGTMEvent('blue_icon_emoji')
                break;
            case "exlamation_mark":
                this.logger.logCustomEvent('Sent Exclamation emoji', { communicate: 'Exclamation', exclamation: 1 })
                this.LogGTMEvent('exclamation_emoji')
                break;
            case "head":
                this.logger.logCustomEvent('Sent Annoyed emoji', { communicate: 'Annoyed', annoyed: 1 })
                this.LogGTMEvent('annoyed_emoji')
                break;
            case "heart_eyes":
                this.logger.logCustomEvent('Sent Heart Eyes emoji', { communicate: 'Heart Eyes', hearteyes: 1 })
                this.LogGTMEvent('heart_eyes_emoji')
                break;
            case "red_heart":
                this.logger.logCustomEvent('Sent Red Heart emoji', { communicate: 'Red Heart', redheart: 1 })
                this.LogGTMEvent('red_heart_emoji')
                break;
        }
    }

    LogAnimation(event) {
        switch (event) {
            case 3:
                this.logger.logCustomEvent('Clap Animation', { animation: 'Clap', clap: 1 })
                this.LogGTMEvent('clap_animation');
                console.log("Log animation: Clap")
                break;
            case 4:
                this.logger.logCustomEvent('Dance Animation', { animation: 'Dance', dance: 1 })
                this.LogGTMEvent('dance_animation');
                console.log("Log animation: Dance")
                break;
            case 5:
                this.logger.logCustomEvent('Jump Animation', { animation: 'Jump', jump: 1 })
                this.LogGTMEvent('jump_animation');
                console.log("Log animation: Jump")
                break;
        }
    }
    
    LogGTMEvent(eventTrigger, variableID = null, variableValue = null) {
        if (window.dataLayer) {
            let eventData = {
                'event': eventTrigger,
            };
            if (variableID && variableValue){
                eventData[variableID] = variableValue;
            }
            window.dataLayer.push(eventData);
        }
    }

    EndMetalitixSession() {
        this.logger.endSession();
        console.log("MTX Session Ended")
    }
}

const instance = new AnalyticsEvents();
export default instance;