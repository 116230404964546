import { AudioManager } from "../AudioManager";

export class Keyboard {
    static keysMapping = {
        87: {
            key: "w",
            keycode: 87,
            inputX: 0,
            inputY: -1,
        },
        65: {
            Key: "a",
            keycode: 65,
            inputX: -1,
            inputY: 0,
        },
        83: {
            key: "s",
            keycode: 83,
            inputX: 0,
            inputY: 1,
        },
        68: {
            key: "d",
            keycode: 68,
            inputX: 1,
            inputY: 0,
        },
    }
    constructor() {
        this._input = {
            x: 0,
            y: 0
        }

        this.handleKeyDown = (e) => {
            //console.log('handleTouchStart', e)
            // e.preventDefault();

            const keyCode = e.keyCode;
            const key = Keyboard.keysMapping[keyCode];

            if (!key) return;

            let x = key?.inputX != 0 ? key.inputX : this._input.x;
            let y = key?.inputY != 0 ? key.inputY : this._input.y;

            this._input = {
                x: x,
                y: y
            }
        }

        this.handleKeyUp = (e) => {
            //console.log('handleTouchStart', e)
            // e.preventDefault();
            try {
                AudioManager.PlayQAVideo(true);
            } catch (e) {
                console.error(e);
            }

            const keyCode = e.keyCode;
            const key = Keyboard.keysMapping[keyCode];

            if (!key) return;

            let x = key?.inputX != 0 ? 0 : this._input.x;
            let y = key?.inputY != 0 ? 0 : this._input.y;

            this._input = {
                x: x,
                y: y
            }
        }

        document.addEventListener('keydown', this.handleKeyDown, { passive: false });
        document.addEventListener('keyup', this.handleKeyUp, { passive: false });
    }

    GetInput() {
        return this._input;
    }

    remove() {
        document.removeEventListener('keydown', this.handleKeyDown)
        document.removeEventListener('keyup', this.handleKeyUp)
    }
}

/*document.addEventListener('keydown', (e) => {

                    switch (e.keyCode) {
                        case 87:
                            //W
                            this.keyBoardInput.y = -1;
                            break;
                        case 65:
                            //A
                            this.keyBoardInput.x = -1;
                            break;
                        case 83:
                            //S
                            this.keyBoardInput.y = 1;
                            break;
                        case 68:
                            //D
                            this.keyBoardInput.x = 1;
                            break;
                        default:
                    }


                });

                document.addEventListener('keyup', (e) => {

                    switch (e.keyCode) {
                        case 87:
                            //W
                            this.keyBoardInput.y = 0;
                            break;
                        case 65:
                            //A
                            this.keyBoardInput.x = 0;
                            break;
                        case 83:
                            //S
                            this.keyBoardInput.y = 0;
                            break;
                        case 68:
                            //Dwa
                            this.keyBoardInput.x = 0;
                            break;
                        default:
                    }


                });
                */

