export class PlayerData{
    data = [-1, -1, -1, -1, -1, -1, -1, -1, -1];

    constructor(position, rotation, modelIndex, animationIndex, levelIndex) {
        this.data[0] = position?.x;
        this.data[1] = position?.y;
        this.data[2] = position?.z;


        this.data[3] = rotation?.x;
        this.data[4] = rotation?.y;
        this.data[5] = rotation?.z;

        this.data[6] = modelIndex;
        this.data[7] = animationIndex;
        this.data[8] = levelIndex;
    }

    GetData() {
        return this.data;
    }
}