import { AudioManager } from "../AudioManager";

export class Joystick {

    constructor(overlay) {
        if (!overlay || overlay == null) {
            throw new Error("Overlay is not set!");
        }

        this.handleTouchStart = () => {
        }

        this.handleTouchMove = (e) => {
            if (this.startPositionRaw == null) {
                this.joystickParent.classList.add('visible');
                this.startPositionRaw = { x: e.touches[0].clientX, y: e.touches[0].clientY };
                try {
                    AudioManager.PlayQAVideo(true);
                } catch (e) {
                    console.error(e);
                }
            }
            //console.log('handleTouchMove', e);
            e.preventDefault();
            this.positionRaw = { x: e.touches[0].clientX, y: e.touches[0].clientY }
        }

        this.clearTouch = () => {
            //console.log('clearTouch', e);
            this.joystickParent.classList.remove('visible');
            this.startPositionRaw = null
        }

        overlay.addEventListener('touchstart', this.handleTouchStart, { passive: false })
        overlay.addEventListener('touchmove', this.handleTouchMove, { passive: false })
        overlay.addEventListener('touchend', this.clearTouch, { passive: false })
        overlay.addEventListener('touchcancel', this.clearTouch, { passive: false })

        this.joystickParent = document.createElement('div')
        this.joystickParent.classList.add('joystick-container', 'absolute-fill', 'shadowed', 'joystickHint')

        this.joystickPosition = document.createElement('div')
        this.joystickPosition.classList.add('joystick', 'position')
        this.joystickParent.appendChild(this.joystickPosition)

        this.joystickOrigin = document.createElement('div')
        this.joystickOrigin.classList.add('joystick', 'origin')
        this.joystickParent.appendChild(this.joystickOrigin)

        overlay.appendChild(this.joystickParent)

    }

    GetInput() {
        const { startPositionRaw, positionRaw } = this
        var x = 0;
        var y = 0;
        
        if (startPositionRaw && positionRaw) {
            const maxRawDistance = Math.min(window.innerWidth, window.innerHeight) / 15;

            let rawOffsetX = positionRaw.x - startPositionRaw.x
            let rawOffsetY = positionRaw.y - startPositionRaw.y

            const rawDistance = Math.sqrt(Math.pow(rawOffsetX, 2) + Math.pow(rawOffsetY, 2))

            if (rawDistance > maxRawDistance) {
                rawOffsetX *= maxRawDistance / rawDistance
                rawOffsetY *= maxRawDistance / rawDistance
            }

            x = rawOffsetX / maxRawDistance;
            y = rawOffsetY / maxRawDistance;

            this._displayJoystickOffset(rawOffsetX, rawOffsetY);
        } 

        return {
            x: x,
            y: y,
        }
    }

    _displayJoystickOffset(x, y) {
        this.joystickParent.classList.remove('joystickHint')

        const { startPositionRaw } = this

        const widthScale = 100 / window.innerWidth
        const heightScale = 100 / window.innerHeight

        this.joystickOrigin.style.left = `${startPositionRaw.x * widthScale}%`
        this.joystickOrigin.style.top = `${startPositionRaw.y * heightScale}%`
        this.joystickPosition.style.left = `${(startPositionRaw.x + x) * widthScale}%`
        this.joystickPosition.style.top = `${(startPositionRaw.y + y) * heightScale}%`
    }

    remove() {
        window.removeEventListener('touchstart', this.handleTouchStart)
        window.removeEventListener('touchmove', this.handleTouchMove)
        window.removeEventListener('touchend', this.clearTouch)
        window.removeEventListener('touchcancel', this.clearTouch)

        this.joystickParent.parentNode.removeChild(this.joystickParent)
    }
}

