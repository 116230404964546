<template>
    <div id="instructionsModal" v-if="displayInstructions && userAgent === 'desktop'">
        <img src="../assets/images/ui/instructions-desktop.svg" alt="Alternate Text" />
        <button id="interactionPopupCloseBtn"
                class="dftButton noSelect smallText"
                @click="switchInstructionWindow(false)">
            <img src="../assets/images/ui/CloseBtn.svg" alt="Alternate Text" />
        </button>
    </div>
    <div id="background" class="infront" v-if="!progress.isModelLoaded">
        <img id="background-img"
             src="../assets/images/ui/Background.svg" />
        <div id="arrow-down-container"></div>

        <img id="axe-background-img"
             src="../assets/images/ui/Axe-logo.svg" />
        <div id="loadingScreenInfo">
            <div style="display:flex;flex-direction: column; gap: 30px;">
                <img src="../assets/images/ui/Axe-logo.svg" />
                <h1>YOU ARE BEING ADDED TO A LOBBY</h1>
            </div>
            <h1>Loading...</h1>
        </div>
    </div>
    <div class="headerBar">
        <a id="headerLogoContainer" href="https://www.axe.com/us/en/home.html" target="_blank">
            <img src="../assets/images/ui/Axe-logo.svg"/>
        </a>
        <div>
            <button id="termsButton"
                    class="dftButton noSelect smallText"
                    @click="()=>{
                        switchTermsPopup()
                        switchInteractionPopup(false)
                        switchInstructionWindow(false)
                    }">
                <img src="../assets/images/ui/instructionsBtn.svg" alt="Alternate Text" />
            </button>
        </div>
        <button class="dftButton noSelect smallText modileInteractionBtn"
                @click="()=>{
                    switchInteractionPopup()
                    switchInstructionWindow(false)
                    switchTermsPopup(false)
                }">
            <img src="../assets/images/ui/Button_active.svg" alt="Alternate Text" />
            <span>Interact</span>
        </button>
        <img id="background-img"
             style="z-index: -1"
             src="../assets/images/ui/Background.svg" />
    </div>
    <div ref="sceneContainer"
         class="sceneContainer">
    </div>
    <div id="overlay">
        <div id="joystickContainer"
             ref="joystickContainer"
             :style="`display: ${userAgent === 'desktop'? 'none' : 'block'}`">
        </div>
        
        <button id="muteBtn"
                class="dftButton noSelect smallText"
                @click="audioManager.muteFunc((state)=>{audioManager.isMuted = state})">
            <img src="../assets/images/ui/roundBtn_empty.svg" alt="Alternate Text" />
            <img src="../assets/images/ui/volume.svg" class="icon" alt="Alternate Text" v-show="!audioManager.isMuted" />
            <img src="../assets/images/ui/volume-slash.svg" alt="Alternate Text" class="icon" v-show="audioManager.isMuted" />
        </button>

        <button id="backToAtlBtn"
                class="dftButton noSelect"
                @click="switchRooms(0)"
                v-if="displayBackToAtlBtn">
            <img src="../assets/images/ui/Button_active.svg" alt="Alternate Text" />
            <span>Back Outside</span>
        </button>

        <button id="shareMangaBtn"
                class="dftButton noSelect commonText"
                @click="ShareManga()"
                v-show="displayDownloadButton && isMangaReady">
            <img src="../assets/images/ui/download_manga_b.svg" alt="Alternate Text" />
            <span :class="{dotsAnimation:isDownloadInProgress}">{{isDownloadInProgress? 'Downloading' : 'Download Manga'}}</span>
        </button>

        <div id="interactionPopupContainer" v-if="displayInstructions && !displayInteractionPopup && userAgent === 'mobile'">
            <img src="../assets/images/ui/InteractionsPopup.svg" alt="Alternate Text" />
            <div id="emojiContainer">
                <p class="common">Tap and drag to move around with the joystick!</p>
            </div>
            <button id="interactionPopupCloseBtn"
                    class="dftButton noSelect smallText"
                    @click="switchInstructionWindow(false)">
                <img src="../assets/images/ui/CloseBtn.svg" alt="Alternate Text" />
            </button>
        </div>

        <div id="interactionPopupContainer" v-show="displayInteractionPopup">
            <img src="../assets/images/ui/InteractionsPopup.svg" alt="Alternate Text" />
            <div id="emojiContainer">
                <div id="emojis">
                    <button @click="TriggerEmoji('blue_icon')">
                        <img src="../assets/images/ui/emoji/blue_icon.svg" alt="Alternate Text" />
                    </button>
                    <button @click="TriggerEmoji('exlamation_mark')">
                        <img src="../assets/images/ui/emoji/exclamation_mark.svg" alt="Alternate Text" />
                    </button>
                    <button @click="TriggerEmoji('head')">
                        <img src="../assets/images/ui/emoji/head.svg" alt="Alternate Text" />
                    </button>
                    <button @click="TriggerEmoji('heart_eyes')">
                        <img src="../assets/images/ui/emoji/heart_eyes.svg" alt="Alternate Text" />
                    </button>
                    <button @click="TriggerEmoji('red_heart')">
                        <img src="../assets/images/ui/emoji/red_heart.svg" alt="Alternate Text" />
                    </button>
                </div>
                <div id="animations">
                    <button class="dftButton noSelect smallText"
                            @click="Interaction(3)">
                        <img src="../assets/images/ui/Button_animation.svg" alt="Alternate Text" />
                        <span>Clap</span>
                    </button>
                    <button class="dftButton noSelect smallText"
                            @click="Interaction(4)">
                        <img src="../assets/images/ui/Button_animation.svg" alt="Alternate Text" />
                        <span>Dance</span>
                    </button>
                    <button class="dftButton noSelect smallText"
                            @click="Interaction(5)">
                        <img src="../assets/images/ui/Button_animation.svg" alt="Alternate Text" />
                        <span>Jump</span>
                    </button>
                </div>
            </div>
            <button id="interactionPopupCloseBtn"
                    class="dftButton noSelect smallText"
                    @click="switchInteractionPopup(false)">
                <img src="../assets/images/ui/CloseBtn.svg" alt="Alternate Text" />
            </button>
        </div>

        <div id="termsPopupContainer" ref="termsRef" v-show="displayTermsPopup">
            <p id="showInstructions" @click="()=>{
                switchInstructionWindow(true)
                switchTermsPopup(false)   
            }">SHOW INSTRUCTIONS</p>
            <img class="background" src="../assets/images/ui/InteractionsPopup.svg" alt="Alternate Text" />
            <span><a href="https://axegoat.com/terms.pdf" target="_blank">Terms and Conditions</a></span>
            <span><a href="https://www.unilevernotices.com/privacy-notices/usa-english.html" target="_blank">Privacy Policy</a></span>
            <span><a href="https://privacy.unileversolutions.com/en/rights-request-form.html" target="_blank">Do Not Sell or Share My Personal Information</a></span>
            <span><a href="https://notices.unilever.com/en/us/" target="_blank">Accessibility</a></span>
            <span><a href="https://www.unileverus.com/terms/termsofuse.html" target="_blank">Terms of Use</a></span>
            <button id="termsPopupCloseBtn"
                    class="dftButton noSelect smallText"
                    @click="switchTermsPopup(false)">
                <img src="../assets/images/ui/CloseBtn.svg" alt="Alternate Text" />
            </button>
        </div>

        <div id="desktopInteractionBtnContainer">
            <button class="dftButton noSelect commonText"
                    @click="switchInteractionPopup()">
                <img src="../assets/images/ui/Button_active.svg" alt="Alternate Text" />
                <span>Interact</span>
            </button>
        </div>

        <div id="enterPortalBtnContainer">
            <button class="dftButton noSelect enterPortalBtn"
                    @click="switchRooms(1)"
                    v-show="portalButton.isFacing">
                <img src="../assets/images/ui/Button_active.svg" alt="Alternate Text" />
                <!-- <img src="../assets/images/ui/Button_disabled.svg" alt="Alternate Text" v-show="!portalButton.isPortalOppened" /> -->
                <span>Enter portal</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { CommunicationController } from "@/controller/CommunicationController";
    import { ModelsController } from "@/controller/ModelsController";

    import { SceneInstance } from "@/class/SceneInstance";
    import { Joystick } from "@/class/input/Joystick";
    import { Keyboard } from "@/class/input/Keyboard";
    import { wsClient } from "@/class/websocket/wsClient";
    import { AudioManager } from "@/class/AudioManager";

    import levels from '@/json/levels.json'
    import analytics from '@/class/AnalyticsEvents';

    export default {
        name: 'ThreejsCanvas',
        data() {
            return {
                avatarIndex: -1,
                levels: levels,
                sceneEvents: {
                    emojiAnimationCallback: null,
                    emojiTriggerCallback: null,
                    onPlayerTransformChanged: null,
                    onUpdateFromServer: null,
                    onPlayerDisconnected: null,
                    onPlayerConnected: null,
                    onCountdownUpdated: null,
                    onPortalFaced: null,
                    onEmojiTriggered: null,
                    onPlayerEnterRoom: null,
                    onPlayerClickOnPortal: null
                },
                sceneStates: {
                    isEmojiPlaying: false,
                },
                emojisSpamDelay: 3000,
                audioManager: {
                    muteFunc: null,
                    isMuted: null
                },
                isDownloadInProgress: false,
                connection: null,
                displayInteractionPopup: false,
                displayTermsPopup: false,

                displayInstructions: true,
                displayDownloadButton: false,
                isMangaReady:false,
                displayBackToAtlBtn: false,
                portalButton: {
                    isFacing: false,
                    isEventStarted: false, 
                },

                progress: {
                    isModelLoaded: false,
                },
                userAgent: null,
            }
        },
        created() {
            window.addEventListener('beforeunload', this.TerminateSession, false);
        },
        mounted() {
            this.Entry();
            this.sceneEvents.onPortalFaced = this.OnPortalFaced;
            window.addEventListener('resize', this.getUserAgent);
            this.getUserAgent();

            this._setAudioManagerData();

            const evidonNoticeLink = document.querySelector('.evidon-notice-link');
            if (evidonNoticeLink != null) {
                this.$refs.termsRef.appendChild(evidonNoticeLink);
            }
        },
        methods: {
            async Entry() {
                this.CreateModelsController();
                const sceneContainer = this.$refs.sceneContainer;

                //--------Staging api key of Room 0---------
                //const  metalitixApiKey = "156bd8fd-5393-4f06-8458-ed1ba03bb157"

                //--------Production api key of Room 0-------
                const metalitixApiKey = "6dfe1838-719d-44b9-8238-9c0ede83156f"

                this.sceneInstance = new SceneInstance(sceneContainer, this.$refs.joystickContainer, this.modelsController, metalitixApiKey);
                this.progress.isModelLoaded = true;
                this.sceneEvents.onPlayerClickOnPortal = () => {
                    this.switchRooms(1);
                }

                this._fetchManga();
            },

            async _fetchManga() {
                const response = await fetch("assets/LilBabyManga-smaller.pdf");
                this.manga = await response.arrayBuffer();
                this.isMangaReady = true;
                console.warn("Manga is ready.");
            },

            async CreateModelsController() {
                const modelsController = new ModelsController();
                this.modelsController = modelsController.Init();
            },

            LaunchScene(avatarIndex) {
                this.avatarIndex = avatarIndex;
                this.CreateSceneInstance(this.levels.room_1, 0);
                this.EstablishWebsocketConnection();
            },

            async CreateSceneInstance(levelName, roomIndex) {
                this.progress.isModelLoaded = false;
                const sceneContainer = this.$refs.sceneContainer;

                const joystick = new Joystick(this.$refs.joystickContainer);
                const keyboard = new Keyboard();

                const avatarIndex = this.avatarIndex;
                const onLoadedCallback = () => {
                    this.progress.isModelLoaded = true;
                    if (roomIndex == 1) {
                        setTimeout(() => {
                            this.displayDownloadButton = true;
                        }, 3000);
                        this.displayBackToAtlBtn = true;
                        if (this.userAgent == "desktop") {
                            AudioManager.PlayQAVideo(true);
                        }
                    } else {
                        this.displayDownloadButton = false;
                        this.displayBackToAtlBtn = false;
                    }
                    setTimeout(() => {
                        this.switchInstructionWindow(false, false)
                    }, 5000);
                }

                let metalitixApiKey = ""
                if(roomIndex == 0){
                    //--------Staging api key---------
                    //metalitixApiKey = "156bd8fd-5393-4f06-8458-ed1ba03bb157"

                    //--------Production api key-------
                    metalitixApiKey = "6dfe1838-719d-44b9-8238-9c0ede83156f"
                } else if (roomIndex == 1){
                    //-------Staging api key----------
                    //metalitixApiKey = "5c048a32-b3e1-4895-9a0a-2c91aa07cbda"

                    //------Production api key---------
                    metalitixApiKey = "472b0171-3ab4-4c29-9f33-c346f77f5ad7"
                }
                
                if (!this.sceneInstance) 
                    this.sceneInstance = new SceneInstance(sceneContainer, this.$refs.joystickContainer, this.modelsController, metalitixApiKey);


                if (this.avatarIndex == -1) return;
                this.sceneInstance.InitializeScene(
                    levelName,
                    avatarIndex,
                    onLoadedCallback,
                    joystick,
                    keyboard,
                    this.sceneEvents,
                    this.sceneStates);
            },

            async EstablishWebsocketConnection() {
                const matchmakingAddress = "https://odmy4ecpd7tsxfs53nxbyhcpru0dacar.lambda-url.us-east-1.on.aws/"
                const match = await fetch(matchmakingAddress).then(res => res.json());
                const domain = 'axe-multiplayer.aircards.io'
                if (!match[0]) {
                    console.error(match[0]);
                }
                const { port } = match[0];
                const address = `wss://${domain}:${port}`;

                if (this.client == null) {
                    this.client = new wsClient({
                        onOpen: (e) => {
                            this.onOpen(e);
                        },
                        onClose: (e) => {
                            this.onClose(e);
                            if (this.reconectionTimeout != null) return;
                            this.reconectionTimeout = setTimeout(() => {
                                clearTimeout(this.reconectionTimeout);
                                this.reconectionTimeout = null;
                                this.EstablishWebsocketConnection();
                            }, this.client.reconectionTimeout || 1000);
                        },
                        onMessage: (e) => {
                            this.onMessage(e);
                        },
                        onError: (e) => {
                            this.onError(e);
                        },
                    });
                    this.communicationController = new CommunicationController(this.client, this.sceneEvents);
                }
                this.client.Connect(address);
            },

            getUserAgent() {
                // return desktop or mobile
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
                    this.userAgent = "mobile";
                } else {
                    this.userAgent = "desktop";
                }
            },

            _setAudioManagerData() {
                this.audioManager.muteFunc = AudioManager.Mute;
                this.audioManager.isMuted = AudioManager.isMuted;
            },

            async ShareManga() {   
                if (this.isDownloadInProgress) return;

                this.isDownloadInProgress = true;
                setTimeout(() => {
                    this.isDownloadInProgress = false;
                }, 5000)

                const pdf = new File([this.manga], "LilBabyManga.pdf", { type: "application/pdf" });
                const files = [pdf];


                if (navigator.share && this.userAgent !== "desktop") {
                    try {
                        await navigator.share({ files })
                        analytics.LogCTA("Share");
                        this.isDownloadInProgress = false;
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    
                    var link = document.createElement('a');
                    link.href = "assets/LilBabyManga-smaller.pdf";
                    link.download = "LilBabyManga.pdf";
                    link.dispatchEvent(new MouseEvent('click'));
                    analytics.LogCTA("Download");
                }
            },

            Interaction(animationName) {
                if (this.sceneEvents.emojiAnimationCallback) {
                    this.sceneEvents.emojiAnimationCallback(animationName);
                    this.switchInteractionPopup(false);
                }
            },

            TerminateSession() {
                if (!this.communicationController) return;

                analytics.LogGTMEvent("session_ended")
                this.communicationController.TerminateSession();
                return null;
            },

            switchInteractionPopup(state) {
                this.ButtonSound();
                if (state != undefined) {
                    this.displayInteractionPopup = state;
                    return;
                }
                this.displayInteractionPopup = !this.displayInteractionPopup;
            },

            switchInstructionWindow(state, playSound = true) {
                if (playSound) {
                    this.ButtonSound();
                }
                if (state != undefined) {
                    this.displayInstructions = state;
                    return;
                }
                this.displayInstructions = !this.displayInstructions;
            },

            switchTermsPopup(state, playSound = true) {
                if (playSound) {
                    this.ButtonSound();
                }
                if (state != undefined) {
                    this.displayTermsPopup= state;
                    return;
                }
                this.displayTermsPopup = !this.displayTermsPopup;
            },

            OnPortalFaced(state, isPortalOppened) {
                this.portalButton.isFacing = state;
                this.portalButton.isPortalOppened = isPortalOppened;
            },

            switchRooms(level) {
                let room = this.levels.room_1;

                switch (level) {
                    default:
                    case 0:
                        room = this.levels.room_1;
                        analytics.LogWentBackOutside();
                        break;
                    case 1:
                        room = this.levels.room_2;
                        analytics.LogUsedPortal();
                        break;
                }

                this.ButtonSound();

                if (level == 1) {
                    this.PortalSound();
                }

                AudioManager.StopCurrentAmbience();

                this.progress.isModelLoaded = false;
                this.sceneInstance.Remove(() => {
                    this.sceneInstance = undefined;
                    this.CreateSceneInstance(room, level);
                });
            },

            TriggerEmoji(emoji) {
                if (this.isEmojiPlaying) return;
                this.isEmojiPlaying = true;
                setTimeout(() => {
                    this.isEmojiPlaying = false;
                }, this.emojisSpamDelay)
                analytics.LogCommunicate(emoji);
                this.communicationController.TriggerEmoji(emoji);
            },

            ButtonSound() {
                AudioManager.PlaySound(AudioManager.sounds.buttonPress);
            },

            PortalSound() {
                AudioManager.PlaySound(AudioManager.sounds.portal);
            },

            //Optional
            onOpen() {
                console.warn("open");
            },

            onClose() {
                console.warn("close");
            },

            onMessage(e) {
                this.communicationController.OnMessageReceived(e);
            },

            onError(e) {
                console.warn(e);
                console.warn("error");
            }
        }
    }
</script>


<style scoped>
    @import "../scss/animations.scss";
    @import "../scss/joystick.scss";
    @import "../scss/styles.scss";
</style>
