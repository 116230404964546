import * as THREE from 'three';
import anims from '@/json/animations.json'

export class Character {
    _character;
    _mesh;
    _animations;
    _camera;
    _mixer;

    _inputX;
    _inputY;

    _movementSpeed = 4;
    _rotationSpeed = 180;
    _inputDeadZoneY = 0.1;

    _cameraOffset = new THREE.Vector3(0, 2.2, -1.5);
    _cameraRotation = new THREE.Vector3(25, 180, 0);

    _currentAnimation = 0;
    _emojiAnimation = -1;
    _emojiAnimationTimeout = null;

    constructor(character, camera, avatarIndex) {
        this._character = character;
        this._mesh = character.scene;
        this._avatarIndex = avatarIndex;
        this._animations = character.animations;
        this._camera = camera;
        this._mixer = new THREE.AnimationMixer(this._mesh);

        this._playAnimation(0);
    }

    Move(input, groundLevel, deltaTime) {
        this._inputX = -input.x;
        this._inputY = -input.y;

        this._move(groundLevel, deltaTime);
        this._rotate(deltaTime);
        this._mixer.update(deltaTime);
        this._manageAnimations();
    }

    SetRotation(angle) {
        this._mesh.rotation.y = angle;
    }

    PlayEmojiAnimation(animationName) {
        this._emojiAnimation = animationName;
        this._playAnimation(this._emojiAnimation, true);
    }

    GetDirection() {
        let direction = new THREE.Vector3();
        this._mesh.getWorldDirection(direction);
        return direction;
    }

    GetMesh() {
        return this._mesh;
    }

    GetAvatarIndex() {
        return this._avatarIndex;
    }

    GetCurrentAnimation() {
        return this._currentAnimation;
    }

    GetCameraOffset() {
        return this._cameraOffset;
    }

    _manageAnimations() {
        let animation = this._currentAnimation;
        let emojiAnimation = this._emojiAnimation;

        if (this._inputY != 0) {
            this._resetEmojiAnimation();
        }

        if (this._inputY >   0) {
            animation = 1;
        } else if (this._inputY < 0) {
            animation = 2;
        } else {
            animation = emojiAnimation == -1 ? 0 : emojiAnimation;
        }

        if (animation != this._currentAnimation) {
            this._playAnimation(animation);
        }
    }

    _resetEmojiAnimation() {
        this._emojiAnimation = -1;
        if (this._emojiAnimationTimeout) clearTimeout(this._emojiAnimationTimeout);
    }

    SetCameraOffset(posOnHit, deltaTime) {
        const character = this._mesh;
        const camera = this._camera;
        const cameraOffset = this._cameraOffset;
    
        let finalCameraPosition;

        if (posOnHit) {
            finalCameraPosition = posOnHit
            finalCameraPosition.y = 2.5;
        } else {
            const desiredCameraPosition = new THREE.Vector3().copy(character.position);
            desiredCameraPosition.y += cameraOffset.y;
            desiredCameraPosition.add(new THREE.Vector3(0, 0, cameraOffset.z).applyQuaternion(character.quaternion));
            finalCameraPosition = desiredCameraPosition;
        }
    
        // Smoothly move the camera to the final position
        camera.position.lerp(finalCameraPosition, 0.1);
    
        // Make the camera look at the character
        const desiredLookPosition = new THREE.Vector3().copy(character.position);

        if(posOnHit) {
            desiredLookPosition.y += cameraOffset.y * 0.97;
        }
        else {
            desiredLookPosition.y += cameraOffset.y * 0.8;
        }

        const currentQuaternion = new THREE.Quaternion();
        currentQuaternion.copy(camera.quaternion);

        const targetQuaternion = new THREE.Quaternion();
        camera.lookAt(desiredLookPosition);

        targetQuaternion.copy(camera.quaternion);
        camera.quaternion.copy(currentQuaternion);
        camera.quaternion.slerp(targetQuaternion,  deltaTime * 8);
    }

    _move(groundLevel, deltaTime) {
        const movementSpeed = this._movementSpeed;
        const inputY = this._inputY;
        const deadZoneY = this._inputDeadZoneY;
        const character = this._mesh;
        if (Math.abs(inputY) < deadZoneY) return;

        if (groundLevel != null) {
            character.position.y = groundLevel;
            character.translateZ(movementSpeed * inputY * deltaTime);
        }

        character.position.set(
            Math.round(character.position.x * 1000) / 1000,
            Math.round(character.position.y * 1000) / 1000,
            Math.round(character.position.z * 1000) / 1000,
        );
    }

    _playAnimation(animationIndex, isEmoji) {
        const animationName = anims[animationIndex.toString()];
        this._currentAnimation = animationIndex;
        const clip = THREE.AnimationClip.findByName(this._animations, animationName);

        if (!clip) return;

        if (isEmoji) {
            this._emojiAnimationTimeout = setTimeout(() => {
                this._resetEmojiAnimation();
            }, clip.duration * 1000);
        }
        clip.optimize();
        if (this._action) this._action.fadeOut(0.2);
        this._action = this._mixer.clipAction(clip);
        this._action.reset();
        this._action.fadeIn(0.2);
        this._action.play();
    }
    
    _rotate(deltaTime) {
        
        const rotationSpeed = this._rotationSpeed;
        const inputX = this._inputX
        const character = this._mesh;

        const angle = rotationSpeed * inputX * deltaTime;
        let targetAngle = character.rotation.y + THREE.MathUtils.degToRad(angle);
        character.rotation.set(
            0,
            Math.round(targetAngle * 100) / 100,
            0)
    }
}