<template>
    <div class="frontOfOverlay">
        <div style="display: contents">
            <div id="background">
                <img id="background-img"
                     src="../assets/images/ui/Background.svg" />
                <div id="arrow-down-container"></div>

                <img id="axe-background-img"
                     src="../assets/images/ui/Axe-logo.svg" />

            </div>
            <div id="foreground">
                <div id="foreground-header">
                    <a href="https://www.axe.com/us/en/home.html" target="_blank">
                        <img src="../assets/images/ui/Axe-logo.svg" />
                    </a>
                    <h1>{{currentSection <= 2? 'Shonen baby!': 'Instructions'}}</h1>
                </div>
                <div id="foreground-main">
                    <div id="sectionContent" v-show="currentSection == 0">
                        <!--Entry, video-->
                        <!--<img id="videoPlayer" src="../assets/images/ui/VideoFrame.svg" />-->
                        <video id="startVideo"
                               ref="video"
                               playsinline
                               poster="../assets/images/ui/axe_video_thumbnail.jpg"
                               @click="PlayVideo()">
                            <source src="assets/videos/axe_intro_video.mp4"
                                    type="video/mp4">
                        </video>
                        <div id="message1">
                            <p>Welcome to the debut of Lil Baby's debut manga. <br>Get hype because Shonen Baby is legit!</p>
                        </div>
                    </div>
                    <div id="sectionContent" v-show="currentSection == 1">
                        <!--welcome message-->
                        <div id="message1" class="gap">
                            <p>What's up GOAT</p>
                            <p>You made it to the launch party of Shonen Baby.</p>
                            <p>Manga never seen a legend like Lil Baby so you know this is about to be lit.</p>
                            <p> But before you join the party let's get you looking fresh.</p>
                        </div>
                    </div>
                    <div id="sectionContent" v-show="currentSection == 2">
                        <!--Avatar selection-->
                        <div id="avatarsSelection">
                            <div id="avatarsSelectionHeader">
                                <p class="RiftonUpper">Select an avatar</p>
                            </div>
                            <div style="flex:0 0 auto; min-height: 0; overflow: auto;">
                                <div id="avatarsContainer">
                                    <div v-for="(avatarData, index) in avatars"
                                         :key="index"
                                         :class="{selectedAvatar:selectedAvatar == index, avatar:true}"
                                         @click="SelectAnAvatar(index)">
                                        <img :src="GetAvatarPath(avatarData.icon)" alt="Alternate Text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="sectionContent" v-show="currentSection == 3">
                        <div id="message1">
                            <img src="assets/textures/atl.png" alt="Alternate Text" />
                            <p>You're seconds away from Shonen Baby! So let's give you a quick rundown on what's all about to happen</p>
                        </div>
                    </div>
                    <div id="sectionContent" v-show="currentSection == 4">
                        <div id="message1">
                            <img src="assets/textures/rm.png" alt="Alternate Text" />
                            <p>
                                WE'LL DROP YOU IN THE STREETS OF ATLANTA WHERE YOU CAN INTERACT WITH DIFFERENT CHARACTERS FROM LIL BABY'S MANGA BEFORE ENTERING THE MAIN EVENT
                            </p>
                        </div>
                    </div>
                    <div id="sectionContent" v-show="currentSection == 5">
                        <div id="message1">
                            <img src="assets/textures/prtl.png" alt="Alternate Text" />
                            <p>
                                WHEN YOU'RE READY, YOU CAN ENTER THE VIEWING PARTY THROUGH THE PORTAL.
                            </p>
                            <p>
                                Once in the viewing party you'll be able to download the manga and watch Shonen Baby content
                            </p>
                        </div>
                    </div>
                </div>
                <div id="foreground-footer">
                    <div id="buttonsContainer">
                        <button class="dftButton noSelect commonText"
                                @click="BackButtonClick()"
                                v-if="currentSection == 3 || currentSection == 4 ||currentSection == 5">
                            <img src="../assets/images/ui/Button_active.svg" alt="Alternate Text" v-show="currentSection == 3|| currentSection == 4 || currentSection == 5" />
                            <img src="../assets/images/ui/Button_disabled.svg" alt="Alternate Text" v-show="false" />
                            <span>BACK</span>
                        </button>
                        <button class="dftButton noSelect commonText"
                                @click="ButtonClick()">
                            <img src="../assets/images/ui/Button_active.svg" alt="Alternate Text" v-show="buttonActive" />
                            <img src="../assets/images/ui/Button_disabled.svg" alt="Alternate Text" v-show="!buttonActive" />
                            <span>{{currentSection == 5? "LET'S GO": "NEXT"}}</span>
                        </button>
                    </div>
                    <p id="terms" ref="termsRef">
                        By proceeding you agree to our <a href="https://axegoat.com/terms.pdf" target="_blank">Terms and Conditions</a> and <a href="https://www.unilevernotices.com/privacy-notices/usa-english.html" target="_blank">Privacy Policy</a>
                        <br />
                        <span><a href="https://privacy.unileversolutions.com/en/rights-request-form.html" target="_blank">Do Not Sell or Share My Personal Information</a></span>
                        <span><a href="https://notices.unilever.com/en/us/" target="_blank">Accessibility</a></span>
                        <span><a href="https://www.unileverus.com/terms/termsofuse.html" target="_blank">Terms of Use</a></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import avatars from '@/json/avatars.json'
    import { AudioManager } from '@/class/AudioManager';
    import analytics from '@/class/AnalyticsEvents';

    export default {
        name: 'SplashScreen',
        props: {
        },
        data() {
            return {
                iconsPath: "./assets/avatars/",
                amountOfSection: 6,
                currentSection: 0,
                avatars: avatars,
                selectedAvatar: -1,
                buttonActive: true,
            }
        },
        created() {
        },

        watch: {
            selectedAvatar() {
                if (this.currentSection == 2 && this.selectedAvatar == -1) {
                    this.buttonActive = false;
                } else {
                    this.buttonActive = true;
                }
            },
        },
        
        mounted() {
            const evidonNoticeLink = document.querySelector('.evidon-notice-link');
            if (evidonNoticeLink != null) {
                this.$refs.termsRef.appendChild(evidonNoticeLink);
            }
        },

        methods: {
            GetAvatarPath(avatar) {
                return this.iconsPath + avatar;
            },
            buttonDisabled() {
                //todo
            },
            ReloadVideo() {
                const video = this.$refs.video;
                video.load();
            },
            PlayVideo() {

                const video = this.$refs.video;
                video.removeEventListener('ended', this.ReloadVideo);
                video.addEventListener('ended', this.ReloadVideo);
                if (video.currentTime == 0 && video.paused) {
                    video.play();
                    video.onended = function () {
                        video.load();
                    };
                    analytics.LogStartExperience("watchlandingvideo");
                }
            },
            nextSection() {
                this.currentSection++;
                if (this.currentSection == this.amountOfSection) {
                    this.currentSection = 0;
                }

                if (this.currentSection == 2 && this.selectedAvatar == -1) {
                    this.buttonActive = false;
                } else {
                    this.buttonActive = true;
                }
            },
            SelectAnAvatar(avatar) {
                this.ButtonSound();
                this.selectedAvatar = avatar;
                analytics.LogAvatarSelection(this.selectedAvatar);
            },
            ButtonClick() {
                switch (this.currentSection) {
                    case 0:
                        analytics.LogStartExperience("next1");
                        break;
                    case 1:
                        analytics.LogStartExperience("next2");
                        break;
                    case 2:
                        analytics.LogStartExperience("avatarnext");
                        break;
                    case 3:
                        analytics.LogStartInstructions("instructionsnext1");
                        break;
                    case 4:
                        analytics.LogStartInstructions("instructionsnext2");
                        break;
                    case 5:
                        analytics.LogStartInstructions("letsgo");
                        break;
                }

                if (this.currentSection == 0) {
                    const video = this.$refs.video;
                    video.pause();
                }
                if (this.buttonActive) {
                    this.ButtonSound();
                    this.currentSection == 5 ? this.GoToScene() :  this.nextSection();
                } else {
                    this.buttonDisabled();
                }
            },
            BackButtonClick() {
                if (this.currentSection == 3 || this.currentSection == 4 || this.currentSection == 5) {
                    this.ButtonSound();
                    this.currentSection--;
                }
            },
            GoToScene() {
                this.$parent.SwitchToScene(this.selectedAvatar);
            },
            ButtonSound() {
                AudioManager.PlaySound(AudioManager.sounds.buttonPress);
            },
        }
    }
</script>


<style scoped>
    @import "../scss/styles.scss";
</style>
