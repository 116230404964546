import * as THREE from 'three';
import { AudioManager } from './AudioManager';
import { Character } from './Character';
import { Dummy } from './Dummy';
import { PlayerData } from './PlayerData';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import analytics from './AnalyticsEvents.js';

import rigs from '@/json/rigs.json';
import avatars from '@/json/avatars.json'
import emojis from '@/json/emojis.json'

export class SceneInstance {
    isPlayerCreated = false;

    _deltaTime = 0;
    _input = {
        current: {
            x: 0,
            y: 0,
        }
    };
    _hierarchy = {
        player: null,
        dynamic: {},
        static: {
            light: []
        },
        inProgress: {},
    }
    videoControl = {};
    interactable = {};
    countdown = {};
    _level = {
        navmesh: null,
        mesh: null,
        portal: null,
        metalitixApiKey: null,
        index: 0,
        panels: [],
        characters: [],
        spawnpoint: {
            position: null,
            rotation: null,
        }
    }

    _panelsMixers = [];
    _interaction = null;

    bubbles = {
        "thebiter_test03.glb": ["3_1.svg"],
        "axe_test02_unlitnospec.glb": ["2_1.svg", "2_2.svg", "2_3.svg"],
        "lilbaby_test02.glb": ["1_1.svg", "1_2.svg", "1_3.svg"],
    }

    static pending = "Pending";



    constructor(container, overlay, modelsController, metalitixApiKey) {
        console.warn(container);
        console.warn(overlay);
        console.warn(modelsController);
        console.warn("The scene has been created!");
        this.modelsController = modelsController;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.container = container;
        this.overlay = overlay;

        window.addEventListener('resize', this.resizeListener = () => {
            this._resize();
        }, false);
        container.appendChild(this.renderer.domElement);
        this._resize();
        this.InitializeScene = this.InitializeScene.bind(this);
        this._update = this._update.bind(this);
        this._readInput = this._readInput.bind(this);
        this._calculateDeltaTime = this._calculateDeltaTime.bind(this);

        this.camera.position.set(0, -3, 0);
        analytics.InitializeMetalitixLogger(metalitixApiKey, this.camera, this.scene);

        this.StartScene();
    }

    InitializeScene(level, avatarIndex, onLoadedCallback, joystick, keyboard, sceneEvents, sceneStates) {
        this.clock = new THREE.Clock();
        this.clock.autoStart = true;
        this.textureLoader = new THREE.TextureLoader();

        this.joystick = joystick;
        this.keyboard = keyboard;
        this.sceneEvents = sceneEvents;
        this.sceneStates = sceneStates;

        this._setCallbacks();
        this._createRaycaster();
        this._createSceneLight();

        // Time threshold for logging an object as seen (in seconds)
        this.objectSeenThreshold = 1000;
        this.seenCheckInterval = 100;

        this.exportSceneModel = false;
        console.warn(level);
        this._createEnvironment(level, () => {
            this.checkObjectsInterval = setInterval(this._checkIfObjectsAreViewed.bind(this), this.seenCheckInterval);
            this._createCharacter(avatarIndex, () => {
                onLoadedCallback();
                this._initializeOrbitControls();
                this._addClickListener();
            });
        })

        this.emojisPool = {};
        this.isPlayerCreated = true;
    }

    _initializeOrbitControls() {
        // Initialize the controls
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // Disabling controls that you do not need
        this.controls.enableDamping = false;
        this.controls.enablePan = false;
        this.controls.enableZoom = false;
        this.controls.minPolarAngle = Math.PI / 5;
        this.controls.maxPolarAngle = 3 * Math.PI / 4;
        this.controls.maxDistance = 2;  // Set this to the desired camera distance
        // Create a new empty Object3D to serve as the target position
        this.cameraTarget = new THREE.Object3D();
        this.controls.addEventListener('start', () => {
            this.usingOrbitControls = true;
        });
        this.controls.addEventListener('end', () => {
            this.cameraTarget.position.copy(this.camera.position);
            this._calculateCharacterFacingOnOrbit();
            this.usingOrbitControls = false;
        });
        this.controls.addEventListener('change', () => {
            let hitPos = this._calculateCameraPosOnHit(this._level.mesh, this.camera.position)
            if (hitPos) {
                this.camera.position.lerp(hitPos, 0.1);
            }
        });

        this.camera.position.copy(this._getDesiredCameraPosition());
        this.cameraTarget.position.copy(this._getDesiredCameraPosition());
    }
        

    _addClickListener() {
        const handleMove = (e) => {
            var rect = e.target.getBoundingClientRect();
            var x = ((e.clientX - rect.left) / rect.width) *2-1;
            var y = -((e.clientY - rect.top) / rect.height) *2+1;
            const pointer = new THREE.Vector2(x, y);
            console.warn(pointer);
            this.raycaster.setFromCamera(pointer, this.camera);
            if (this.interactable.portal) {
                const portalIntersection = this.raycaster.intersectObject(this.interactable.portal, true);
                if (portalIntersection.length > 0) {
                    if (this.sceneEvents.onPlayerClickOnPortal) {
                        document.body.style.cursor = 'pointer';
                        return
                    }
                }
            }
            if (this.interactable.video) {
                const videointersection = this.raycaster.intersectObject(this.interactable?.video, true);
                if (videointersection.length > 0) {
                    document.body.style.cursor = 'pointer';
                    return
                }
            }
            document.body.style.cursor = 'default';
        }
        

        const handleClick = (e) => {
            var rect = e.target.getBoundingClientRect();
            var x = ((e.clientX - rect.left) / rect.width) *2-1;
            var y = -((e.clientY - rect.top) / rect.height) *2+1;
            const pointer = new THREE.Vector2(x, y);
            console.warn(pointer);
            this.raycaster.setFromCamera(pointer, this.camera);

            if (this.interactable.portal) {
                const portalIntersection = this.raycaster.intersectObject(this.interactable.portal, true);
                if (portalIntersection.length > 0) {
                    if (this.sceneEvents.onPlayerClickOnPortal) {
                        this.sceneEvents.onPlayerClickOnPortal();
                    }
                }
            }
            if (this.interactable.video) {
                const videointersection = this.raycaster.intersectObject(this.interactable?.video, true);
                if (videointersection.length > 0) {
                    AudioManager.PlayQAVideo();
                }
            }
        }

        this.renderer.domElement.onmousemove = handleMove


        this.overlay.onclick = handleClick
        this.renderer.domElement.onclick = handleClick
    }

    getRandomVector3(max) {
        return new THREE.Vector3(Math.floor(Math.random() * max), 0, Math.floor(Math.random() * max));
    }

    StartScene() {
        this.animationFrame = requestAnimationFrame(this._update);
    }

    _loadBubbles(characterData) {
        const heightMultiplayer = characterData.name == "thebiter_test03.glb" ? 1.25 : 1;
        const sizeMultiplayer = characterData.name == "lilbaby_test02.glb" ?0.70 : 1;
        const bubbles = this.bubbles[characterData.name];
        const character = characterData.mesh;
        const map = new THREE.TextureLoader().load(`assets/bubbles/${bubbles[Math.floor(Math.random() * bubbles.length)]}`);
        const material = new THREE.SpriteMaterial({ map: map, color: 0xffffff, fog: true });
        const sprite = new THREE.Sprite(material);
        sprite.position.set(character.position.x, character.position.y + 2 * heightMultiplayer, character.position.z);
        sprite.center = new THREE.Vector2(0, 0);
        sprite.scale.set(2 * sizeMultiplayer, 1 * sizeMultiplayer, 1 * sizeMultiplayer);
        this.scene.add(sprite);
        this._interaction = setTimeout(() => {
            this.scene.remove(sprite);
            setTimeout(() => {
                this._interaction = null
            }, 2000)
        }, 4000)

    }

    _setCallbacks() {
        this.sceneEvents.onUpdateFromServer = (data) => {
            this._updateFromServer(data);
        };

        this.sceneEvents.onPlayerDisconnected = (playerId) => {
            this._removePlayer(playerId);
        };

        this.sceneEvents.onCountdownUpdated = (countdown) => {
            this._onCountdownUpdated(countdown);
        };

        this.sceneEvents.onEmojiTriggered = (emojiData) => {
            this._onEmojiTriggered(emojiData);
        }
        this.sceneEvents.onPlayerConnected = (data) => {
            const clientId = data.clientId;
            const playerData = data.playerData;
            this._addPlayer(clientId, playerData);
        };
    }

    async _updateFromServer(data) {
        const activePlayers = Object.keys(this._hierarchy.dynamic);

        Object.keys(data).forEach(async (clientId) => {
            const index = activePlayers.indexOf(clientId);
            if (index != -1) activePlayers.splice(index, 1);

            if (!this._hierarchy.dynamic[clientId]) {
                if (data[clientId][6] != -1) {
                    this._addPlayer(clientId, data[clientId]);
                }
            }

            if (this._hierarchy.dynamic[clientId] &&
                this._hierarchy.dynamic[clientId] !== SceneInstance.pending) {

                this._hierarchy.dynamic[clientId].data = data[clientId];
                this._hierarchy.dynamic[clientId].dummy.Update(this._hierarchy.dynamic[clientId].data, this._level.index);
            }
        });

        activePlayers.forEach(clientId => {
            if (this._hierarchy.dynamic[clientId] &&
                this._hierarchy.dynamic[clientId] !== SceneInstance.pending) {
                this._removePlayer(clientId);
            }
        });
    }

    _removePlayer(clientId) {
        const dummy = this._hierarchy?.dynamic[clientId]?.dummy;
        const mesh = dummy?.GetMesh();
        if (mesh) {
            this.scene.remove(dummy.GetMesh());
        }
        delete this._hierarchy.dynamic[clientId];
    }

    _onEmojiTriggered(data) {
        if (!this._hierarchy.dynamic[data.player]) {
            this._triggerEmoji(this.player.GetMesh(), data.emoji);
        } else {
            this._triggerEmoji(this._hierarchy.dynamic[data.player].dummy.GetMesh(), data.emoji);
        }
    }


    _addPlayer(clientId, playerData) {
        this._hierarchy.dynamic[clientId] = SceneInstance.pending;
        const modelName = avatars[playerData[6]].model;
        this._createPlayerModel(modelName).then((model) => {
            const dummy = new Dummy(model);
            this._hierarchy.dynamic[clientId] = {
                dummy: dummy,
                data: playerData
            };
            const mesh = dummy.GetMesh();
            if (this._level.spawnpoint.position != null && this._level.spawnpoint.rotation) {
                const offset = Math.random() * 2 - 1;

                mesh.position.set(
                    this._level.spawnpoint.position.x + offset,
                    this._level.spawnpoint.position.y,
                    this._level.spawnpoint.position.z + offset);
                mesh.rotation.set(
                    0,
                    THREE.MathUtils.degToRad(this._level.spawnpoint.rotation.y),
                    0);
            }
            this.scene.add(mesh);
        })
    }

    async _createPlayerModel(modelName) {
        const modelsController = await this.modelsController;
        const model = await modelsController.LoadModel(modelName);
        const rig = rigs[modelName];
        model.animations = modelsController.skeletons[rig].animations.slice();
        return model;
    }

    async _triggerEmoji(playerMesh, emoji) {
        const emojiData = this._getEmojiFromPool(emoji);
        if (emojiData == null) {
            this._addEmojiToPool(emoji, (emojiData) => {
                this._startEmoji(playerMesh, emojiData)

            })
        } else {
            this._startEmoji(playerMesh, emojiData)
        }
    }

    async _startEmoji(playerMesh, emojiData,) {
        this.sceneStates.isEmojiPlaying = true;

        const emojiDuration = 4000;
        const transitionDurtation = 500;
        const targetScale = 7;

        const { mesh } = emojiData;
        mesh.parent = playerMesh;
        mesh.position.set(0, 2.35, 0);
        mesh.scale.set(0, 0, 0);
        mesh.visible = true;
        const intervalTime = transitionDurtation / 30;
        const step = targetScale / intervalTime;
        const idleTime = emojiDuration - 2 * transitionDurtation;

        await new Promise((resolve) => {
            const entryinterval = setInterval(() => {
                const scaleVal = (mesh.scale.x + step) > targetScale ? targetScale : (mesh.scale.x + step);
                mesh.scale.set(scaleVal, scaleVal, scaleVal);
            }, intervalTime);
            setTimeout(() => {
                clearInterval(entryinterval);
                resolve();
            }, transitionDurtation);
        });
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, idleTime);
        });
        await new Promise((resolve) => {
            const exitInterval = setInterval(() => {
                const scaleVal = (mesh.scale.x - step) < 0 ? 0 : (mesh.scale.x - step);
                mesh.scale.set(scaleVal, scaleVal, scaleVal);
            }, intervalTime);
            setTimeout(() => {
                clearInterval(exitInterval);
                resolve();
            }, transitionDurtation);
        });

        mesh.visible = false;
        this.sceneStates.isEmojiPlaying = false;
    }

    async _addEmojiToPool(emoji, callback) {
        const modelsController = await this.modelsController;
        const emojisPath = "emoji";
        const emojiPath = emojis[emoji];
        const emojiP = await modelsController.LoadModel(`${emojisPath}/${emojiPath}`);

        Promise.all([emojiP]).then((emojisLoaded) => {
            const emojiModel = emojisLoaded[0];
            const emojiMesh = emojiModel.scene;
            emojiMesh.visible = false;
            this.scene.add(emojiMesh);

            let mixer = null;

            if (emojiModel.animations.length > 0) {
                mixer = new THREE.AnimationMixer(emojiMesh);
                const action = mixer.clipAction(emojiModel.animations[0]);
                action.play();
            }

            if (!this.emojisPool[emoji]) {
                this.emojisPool[emoji] = [];
            }
            const emojiData = {
                model: emojiModel,
                mesh: emojiMesh,
                mixer: mixer
            };
            this.emojisPool[emoji].push(emojiData)
            callback (emojiData);
        });
    }

    _getEmojiFromPool(emoji) {
        if (!this.emojisPool[emoji] || this.emojisPool[emoji].length == 0) return null;
        const pool = this.emojisPool[emoji];
        for (var i = 0; i < pool.length; i++) {
            const emojiData = pool[i];
            if (!emojiData.mesh.visible) return emojiData;
        }
        return null;
    }

    _resize() {
        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    }

    _readInput() {
        const input = this._input;
        const joystick = this.joystick;
        const keyboard = this.keyboard;

        if (this.joystick) input.touchInput = joystick.GetInput();
        if (this.keyboard) input.keyboardInput = keyboard.GetInput();

        const xInput = input.touchInput.x != 0 ? input.touchInput?.x : input.keyboardInput?.x || 0;
        const yInput = input.touchInput.y != 0 ? input.touchInput?.y : input.keyboardInput?.y || 0;

        input.current = {
            x: xInput,
            y: yInput,
        }
    }

    _createRaycaster() {
        this.raycaster = new THREE.Raycaster();
    }

    _createSceneLight() {
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.25);
        this.scene.add(ambientLight);
        this._hierarchy.static.light.push(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
        directionalLight.position.set(-10, 20, -10);
        this.scene.add(directionalLight);
        this._hierarchy.static.light.push(directionalLight);
    }

    _update() {
        this.animationFrame = requestAnimationFrame(this._update);
        if (this.isPlayerCreated) {
            ///
            this._calculateDeltaTime();
            this._updatePlayers(this._deltaTime);

            if (this.player != null) {
                this._readInput();
                const groundLevel = this._calculateGroundLevel(this._level.navmesh);
                this._detectPortalFacing();
                this._checkPortalState();
                this._checkCharacterInteraction();
                if (this.portal?.mixer) {
                    this.portal?.mixer?.update(this._deltaTime);
                }
                if (this._level.mixer) {
                    this._level.mixer.update(this._deltaTime);
                }
                for (let i = 0; i < this._panelsMixers.length; i++) {
                    this._panelsMixers[i].update(this._deltaTime);
                }
                for (const [, emojiPool] of Object.entries(this.emojisPool)) {
                    for (let i = 0; i < emojiPool.length; i++) {
                        if (emojiPool[i].mesh.visible && emojiPool[i].mixer != null) {
                            emojiPool[i].mixer.update(this._deltaTime);
                        }
                    }
                }

                if (this._input.current.x != 0 || this._input.current.y != 0) {
                    if (this.characterFacingOnOrbitAngle) {
                        this.player.SetRotation(this.characterFacingOnOrbitAngle);
                        this.characterFacingOnOrbitAngle = null;
                    }
                }

                this.player.Move(this._input.current, groundLevel, this._deltaTime);

                const mesh = this.player.GetMesh();
                const avatarIndex = this.player.GetAvatarIndex();
                const currentAnimation = this.player.GetCurrentAnimation();
                if (this.sceneEvents.onPlayerTransformChanged != null) {
                    this.sceneEvents.onPlayerTransformChanged(new PlayerData(
                        mesh.position,
                        mesh.rotation,
                        avatarIndex,
                        currentAnimation,
                        this._level.index,
                    ).GetData())
                }

                this._updateCameraTransform();
            }
        ////
        }
        this.renderer?.render(this.scene, this.camera);
    }

    _checkCharacterInteraction() {
        const characters = this._level.characters;
        for (var i = 0; i < characters.length; i++) {
            const distanceToCharacter = this.player.GetMesh().position.distanceTo(characters[i].mesh.position);
            if (distanceToCharacter < 6) {
                if (this._interaction != null) return;
                this._loadBubbles(characters[i]);
                analytics.LogGeneralEvent("avatar communicated")
                return;
            }
        }
    }

    _getDesiredCameraPosition() {
        const playerOrigin = new THREE.Vector3().copy(this.player.GetMesh().position);
        let newDirection = this.player.GetDirection();
        let camOffset = this.player.GetCameraOffset();

        let desiredCameraPosition = new THREE.Vector3().copy(playerOrigin);
        desiredCameraPosition.y += camOffset.y;
        desiredCameraPosition.add(newDirection.multiplyScalar(camOffset.z));
        return desiredCameraPosition;
    }

    _updateCameraTransform() {
        const playerOrigin = new THREE.Vector3().copy(this.player.GetMesh().position);
        //If there is any player movement then update the camera target
        if (this._input.current.x != 0 || this._input.current.y != 0) {

            let hitPos = this._calculateCameraPosOnHit(this._level.mesh, this.cameraTarget.position)
            if (hitPos != null) {
                this.cameraTarget.position.copy(hitPos)
                this.camera.position.lerp(hitPos, 0.01);
            }
            else {

                // Move the target position directly to the desired position
                this.cameraTarget.position.copy(this._getDesiredCameraPosition());
                this.camera.position.lerp(this.cameraTarget.position, 0.05);
            }

            playerOrigin.y += 1.7;
            this.controls.target = playerOrigin;

            this.controls.update();
        }
        else {
            if (!this.usingOrbitControls) {
                this.camera.position.lerp(this.cameraTarget.position, 0.05);

                playerOrigin.y += 1.7;
                this.controls.target = playerOrigin;

                this.controls.update();
            }
        }
    }

    _checkIfObjectsAreViewed() {
        if(this._level.characters != null && this._level.characters.length > 0){
            for (let i = 0; i < this._level.characters.length; i++) {
                const character = this._level.characters[i];
                if(character.mesh != null && !character.seen){
                    if(this._cameraSeesObject(this.camera,character.mesh,5)){
                        character.seenTimer += this.seenCheckInterval;
                        if(character.seenTimer >= this.objectSeenThreshold){
                            character.seen = true;
                            analytics.LogCharacterSeen(character.name);
                        }
                    }
                }
            }
        }

        if(this.movieMesh != null && !this.movieMesh.seen){
            if(this._cameraSeesObject(this.camera,this.movieMesh,5)){
                this.movieMesh.seenTimer += this.seenCheckInterval;
                if(this.movieMesh.seenTimer >= this.objectSeenThreshold){
                    this.movieMesh.seen = true;
                    analytics.LogQAMovieSeen();
                }
            }
            else{
                this.movieMesh.seenTimer = 0;
            }
        }

        if(this._level.panels != null && this._level.panels.length > 0){
            for (let i = 0; i < this._level.panels.length; i++) {
                const panel = this._level.panels[i];
                //Check if panel has already been seen
                if(panel.mesh !=null && !panel.seen){
                    if (this._cameraSeesObject(this.camera, panel.mesh, 5)) {
                        // If object is seen, increment its "seen" time
                        panel.seenTimer += this.seenCheckInterval;
                        
                        // If "seen" time exceeds threshold, log that the object has been seen
                        if (panel.seenTimer >= this.objectSeenThreshold) {
                            panel.seen = true;
                            analytics.LogArtworkSeen(i+1);
                        }
                    } else {
                        // If object is not seen, reset its "seen" time
                        panel.seenTimer = 0;
                    }
                }
            }
        }
    }

    _updatePlayers(deltaTime) {
        const players = this._hierarchy.dynamic;
        for (const [, instance] of Object.entries(players)) {
            if (instance == SceneInstance.pending) continue;
            const dummy = instance.dummy;
            dummy.UpdateAnim(deltaTime);
        }
    }

    async _createCharacter(avatarIndex, callback) {
        const modelName = avatars[avatarIndex].model;
        const modelsController = await this.modelsController;
        const model = await modelsController.LoadModel(modelName);
        const rig = rigs[modelName];
        model.animations = modelsController.skeletons[rig].animations.slice();
        const mesh = model.scene;
        this.scene.add(mesh);
        const character = new Character(model, this.camera, avatarIndex);
        this.player = character;

        if (this._level.spawnpoint.position != null && this._level.spawnpoint.rotation) {
            this.camera.position.set(
                this._level.spawnpoint.position.x,
                this._level.spawnpoint.position.y,
                this._level.spawnpoint.position.z);

            this.camera.rotation.set(
                0,
                THREE.MathUtils.degToRad(this._level.spawnpoint.rotation.y),
                0);

            const offset = Math.random() * 2 - 1;

            mesh.position.set(
                this._level.spawnpoint.position.x + offset,
                this._level.spawnpoint.position.y,
                this._level.spawnpoint.position.z + offset);
            mesh.rotation.set(
                0,
                THREE.MathUtils.degToRad(this._level.spawnpoint.rotation.y),
                0);
        }

        if (callback) callback(model);
        this.sceneEvents.emojiAnimationCallback = (animationName) => {
            character.PlayEmojiAnimation(animationName);
            analytics.LogAnimation(animationName);
        }
    }

    async _createEnvironment(level, callback) {
        const scene = this.scene;
        const index = level.index;
        const folder = level.folder;
        const modelName = level.model;
        const navmeshName = level.navmesh;
        const portalPosition = level.portalPosition;
        const portalRotation = level.portalRotation;
        const portalScale = level.portalScale;
        const panels = level.panels;
        const characters = level.characters;
        const cubemap = level.cubemap;
        const ambience = level.ambience;
        const track = level.track;
        this._level.metalitixApiKey = level.metalitixApiKey;
        const video = level.video;
        const bridgeMessages = level.bridgeMessages;
        this._level.spawnpoint.position = level.spanwpointPosition;
        this._level.spawnpoint.rotation = level.spawnpointRotation;
        const modelsController = await this.modelsController;
        this.hitTestObjects = [];

        const envP = modelsController.LoadModel(`${folder}/${modelName}`);
        const navmeshP = modelsController.LoadModel(`${folder}/navmesh/${navmeshName}`);

        const promiseArr = [envP, navmeshP]

        if (portalPosition && portalRotation && portalScale) {
            const portalP = modelsController.LoadModel(`portal/portal.gltf`);
            promiseArr.push(portalP)
        }

        if (characters) {
            for (const [, characterData] of Object.entries(characters)) {
                const characterP = modelsController.LoadModel(`characters/${characterData.model}`);
                promiseArr.push(characterP)
            }
        }

        if (panels) {
            for (const [, panelData] of Object.entries(panels)) {
                const panelP = modelsController.LoadModel(`panels/${panelData.model}`);
                promiseArr.push(panelP)
            }
        }

        if(index == 0){
            analytics.LogCharactersNotSeen();
        }
        else if(index == 1){
            analytics.LogArtworkPanelsNotSeen()
            analytics.LogQAMovieNotSeen();
        }

        Promise.all(promiseArr).then(async (models) => {
            let modelindex = 0;
            const model = models[modelindex];
            const mesh = model.scene;
            this._level.mesh = mesh;
            this._level.index = index;
            mesh.scale.set(level.scale, level.scale, level.scale);
            scene.add(mesh);
            this.hitTestObjects.push(mesh);

            if (model.animations.length > 0) {
                this._level.mixer = new THREE.AnimationMixer(mesh);
                const clip = THREE.AnimationClip.findByName(model.animations, "speakers_anim");
                if (clip) {
                    clip.optimize();
                    this._level.action = this._level.mixer.clipAction(clip);
                    this._level.action.play();
                }
            }
            if (bridgeMessages) {

                for (let i = 0; i < bridgeMessages.length; i++) {
                    this.textureLoader.load(
                        bridgeMessages[i].source,
                        (texture) => {
                            const material = new THREE.MeshBasicMaterial({
                                map: texture
                            });
                            var plane = new THREE.Mesh(new THREE.PlaneGeometry(3.65, 1.25), material);
                            plane.overdraw = true;
                            plane.position.set(
                                bridgeMessages[i].position.x,
                                bridgeMessages[i].position.y,
                                bridgeMessages[i].position.z
                            );
                            plane.rotation.set(
                                0,
                                THREE.MathUtils.degToRad(bridgeMessages[i].rotation.y),
                                0
                            );
                            this.scene.add(plane);

                        },
                        undefined,
                        () => {
                            console.error('An error happened.');
                        }
                    );
                }
            }

            if (cubemap) {
                const envMap = new THREE.CubeTextureLoader()
                    .setPath(`/assets/models/${folder}/cubemap/`)
                    .load([
                        'px.jpg',
                        'nx.jpg',
                        'py.jpg',
                        'ny.jpg',
                        'pz.jpg',
                        'nz.jpg'
                    ]);

                const material = new THREE.MeshPhongMaterial({
                    color: 0xffffff,
                    specular: 0x050505,
                    shininess: 50,
                    envMap: envMap,
                    combine: THREE.MixOperation,
                    reflectivity: 0.5
                });

                const speakersName = "speakers_pulsing";
                const speakers = this.scene.getObjectByName(speakersName);
                const children = speakers.children;
                for (var i = 0; i < children.length; i++) {
                    const speakerName1 = `speaker${i + 1}_1`;
                    const speaker1 = this.scene.getObjectByName(speakerName1);
                    if (speaker1) {
                        speaker1.material = material;
                        speaker1.material.needsUpdate = true;
                    }
                    const speakerName2 = `speaker${i + 1}_2`;
                    const speaker2 = this.scene.getObjectByName(speakerName2);
                    if (speaker2) {
                        speaker2.material = material;
                        speaker2.material.needsUpdate = true;
                    }
                    const speakerName3 = `speaker${i + 1}_3`;
                    const speaker3 = this.scene.getObjectByName(speakerName3);
                    if (speaker3) {
                        speaker3.material = material;
                        speaker3.material.needsUpdate = true;
                    }
                }
            }

            modelindex++;

            const navmeshModel = models[modelindex];
            const navmesh = navmeshModel.scene;
            this._level.navmesh = navmesh;
            navmesh.visible = false;
            navmesh.scale.set(level.scale, level.scale, level.scale);
            scene.add(navmesh);

            modelindex++;

            if (portalPosition && portalRotation && portalScale) {
                const portalModel = models[2];
                const portal = portalModel.scene;
                this.interactable.portal = portal;
                this._level.portal = portal;
                portal.scale.set(portalScale, portalScale, portalScale);
                portal.position.set(0, 0, 0);
                scene.add(portal);
                this.portal = {
                    onPortalTriggered: () => {
                        this.portal.isPortlaOpened = true;
                        const openAnimationDuration = THREE.AnimationClip.findByName(this.portal.allAnimations, this.portal.animations.open)?.duration;
                        this._playPortalAnimation(this.portal.animations.open);
                        setTimeout(() => {
                            this._playPortalAnimation(this.portal.animations.idle);
                        }, openAnimationDuration - 0.01 * 1000)
                    },
                    animations: {
                        closed: "portal_closed",
                        open: "portal_open",
                        idle: "portal_idle"
                    },
                    allAnimations: portalModel.animations,
                    isPortlaOpened: false,
                    mixer: new THREE.AnimationMixer(portal),
                    action: null,
                }
                this._playPortalAnimation(this.portal.animations.idle);

                modelindex++
            } else {
                this._level.portal = null;
            }

            if (characters) {
                const keys = Object.keys(characters);
                for (let i = 0; i < keys.length; i++) {
                    const characterIndex = modelindex + i;
                    const characterModel = models[characterIndex];
                    const character = characterModel.scene;
                    this._level.characters.push({
                        name: characters[keys[i]].model,
                        mesh: character,
                        seen: false,
                        seenTimer: 0
                    });

                    character.position.set(characters[keys[i]].position.x, characters[keys[i]].position.y, characters[keys[i]].position.z);
                    character.rotation.set(
                        THREE.MathUtils.degToRad(characters[keys[i]].rotation.x),
                        THREE.MathUtils.degToRad(characters[keys[i]].rotation.y),
                        THREE.MathUtils.degToRad(characters[keys[i]].rotation.z)
                    );
                    character.scale.set(characters[keys[i]].scale.x, characters[keys[i]].scale.y, characters[keys[i]].scale.z);

                    const mixer = new THREE.AnimationMixer(character);
                    this._panelsMixers.push(mixer);
                    const action = mixer.clipAction(characterModel.animations[0]);
                    action.play();
                    scene.add(character);
                }
            }

            if (panels) {
                const keys = Object.keys(panels);
                for (let i = 0; i < keys.length; i++) {
                    const panelindex = modelindex + i;
                    const panelModel = models[panelindex];

                    const panel = panelModel.scene;
                    this._level.panels.push({
                        mesh: panel,
                        seen: false,
                        seenTimer: 0
                    });
                    this.hitTestObjects.push(panel);

                    panel.position.set(panels[keys[i]].position.x, panels[keys[i]].position.y, panels[keys[i]].position.z);
                    panel.rotation.set(
                        THREE.MathUtils.degToRad(panels[keys[i]].rotation.x),
                        THREE.MathUtils.degToRad(panels[keys[i]].rotation.y),
                        THREE.MathUtils.degToRad(panels[keys[i]].rotation.z)
                    );
                    panel.scale.set(panels[keys[i]].scale.x, panels[keys[i]].scale.y, panels[keys[i]].scale.z);

                    const mixer = new THREE.AnimationMixer(panel);
                    this._panelsMixers.push(mixer);
                    const action = mixer.clipAction(panelModel.animations[0]);

                    action.play();

                    scene.add(panel);
                }

            }

            if (video) {
                const buttons = ['assets/textures/play.png', 'assets/textures/pause.png'];
                await new Promise((resolve) => {
                    this.textureLoader.load(
                        buttons[0],
                        (texture) => {
                            const material = new THREE.MeshBasicMaterial({
                                map: texture,
                                transparent: true,
                            });
                            var plane = new THREE.Mesh(new THREE.PlaneGeometry(0.25, 0.25), material);
                            plane.overdraw = true;
                            plane.position.set(
                                video.position.x - 0.1,
                                video.position.y - 0.85,
                                video.position.z
                            );
                            plane.rotation.set(
                                THREE.MathUtils.degToRad(video.rotation.x),
                                THREE.MathUtils.degToRad(video.rotation.y),
                                THREE.MathUtils.degToRad(video.rotation.z)
                            );
                            this.scene.add(plane);
                            this.videoControl.play = plane;
                            resolve();
                        },
                        undefined,
                        () => {
                            console.error('An error happened.');
                        }
                    );
                })
                await new Promise((resolve) => {
                    this.textureLoader.load(
                        buttons[1],
                        (texture) => {
                            const material = new THREE.MeshBasicMaterial({
                                map: texture,
                                transparent: true,
                            });
                            var plane = new THREE.Mesh(new THREE.PlaneGeometry(0.25, 0.25), material);
                            plane.overdraw = true;
                            plane.position.set(
                                video.position.x - 0.1,
                                video.position.y - 0.85,
                                video.position.z
                            );
                            plane.rotation.set(
                                THREE.MathUtils.degToRad(video.rotation.x),
                                THREE.MathUtils.degToRad(video.rotation.y),
                                THREE.MathUtils.degToRad(video.rotation.z)
                            );
                            this.scene.add(plane);
                            this.videoControl.pause = plane;
                            resolve();
                        },
                        undefined,
                        () => {
                            console.error('An error happened.');
                        }
                    );
                })
                
                AudioManager.PlayVideo(video.source, this.videoControl, (v) => {
                    const videoTexture = new THREE.VideoTexture(v);
                    const material = new THREE.MeshBasicMaterial({
                        map: videoTexture,
                        side: THREE.FrontSide,
                        toneMapped:false,
                    })
                    const movieGeometry = new THREE.PlaneGeometry(1, 1);
                    const movieMesh = new THREE.Mesh(movieGeometry, material);
                    movieMesh.position.set(
                        video.position.x,
                        video.position.y,
                        video.position.z
                    );
                    movieMesh.rotation.set(
                        THREE.MathUtils.degToRad(video.rotation.x),
                        THREE.MathUtils.degToRad(video.rotation.y),
                        THREE.MathUtils.degToRad(video.rotation.z)
                    );
                    movieMesh.scale.set(
                        video.scale.x,
                        video.scale.y,
                        video.scale.z
                    );
                    movieMesh.seen = false
                    movieMesh.seenTimer = 0

                    this.interactable.video = movieMesh;
                    this.scene.add(movieMesh);
                    this.movieMesh = movieMesh;
                });
            }

            if (ambience && ambience != null) {
                AudioManager.PlaySound(ambience, true, 0.25)
            }
            if (track && track != null) {
                AudioManager.PlaySound(track, true, 0.5)
            }

            if (this.exportSceneModel) modelsController.ExportModel(this.scene);

            if (callback) callback(model);
        });
    }

    _checkPortalState() {
        const countdown = this.countdown.data;
        if (countdown?.raw <= 0) {
            this.portal.onPortalTriggered();
        }

        if (countdown?.seconds % 2 == 0 && !this.portal?.isPortlaOpened) {
            this.portal?.onPortalTriggered();
        }
    }

    _cameraSeesObject(camera, targetObject, raycastDistance, heightOffset = 0, hitTestObjects = null) {
        // Create a new raycaster
        let raycaster = new THREE.Raycaster();

        // Calculate the ray origin
        let rayOrigin = camera.position.clone();
        rayOrigin.y += heightOffset; // Add the height offset

        // Get the camera's forward direction
        let cameraDirection = new THREE.Vector3(0, 0, -1);
        cameraDirection.applyQuaternion(this.camera.quaternion);

        // Set the raycaster's ray
        raycaster.set(rayOrigin, cameraDirection);

        // Perform the raycast

        if(hitTestObjects == null) {
            // Perform the raycast
            let intersects = raycaster.intersectObject(targetObject);

            // Check if the intersection distance is within the specified raycast distance
            if (intersects.length > 0 && intersects[0].distance <= raycastDistance) {
                return true;
            } else {
                return false;
            }
        }
        else {
            let intersects = raycaster.intersectObjects(hitTestObjects);

            // If there are no intersections, return false
            if (intersects.length === 0) {
                return false;
            }
    
            // If the first intersection is the target object and it's within the raycast distance, return true
            if (intersects[0].object === targetObject && intersects[0].distance <= raycastDistance) {
                return true;
            }
    
            // Otherwise, return false
            return false;
        }
    }




    _detectPortalFacing() {
        if (this._level.portal == null) {
            if (this.sceneEvents.onPortalFaced) this.sceneEvents.onPortalFaced(false, false);
            return;
        }
        const playerMesh = this.player.GetMesh();
        const distanceToPortal = playerMesh.position.distanceTo(this._level.portal.position);

        if (distanceToPortal < 3) {
            this.sceneEvents.onPortalFaced(true, this.portal?.isPortlaOpened);
            return;
        }
        if (this.sceneEvents.onPortalFaced) this.sceneEvents.onPortalFaced(false, this.portal?.isPortlaOpened);
    }

    _calculateCharacterFacingOnOrbit() {
        let cameraDirection = new THREE.Vector3(0, 0, 1);
        cameraDirection.applyQuaternion(this.camera.quaternion);

        let cameraDirectionProjected = new THREE.Vector3(cameraDirection.x, 0, cameraDirection.z);
        let worldForward = new THREE.Vector3(0, 0, -1);

        let angle = cameraDirectionProjected.angleTo(worldForward);
        let cross = cameraDirectionProjected.cross(worldForward);
        if (this.camera.rotation.y >= 0) {
            if (cross.y > 0) { // if the camera is to the left of the world's forward direction
                angle *= -1;
            }
        }
        this.characterFacingOnOrbitAngle = angle;
    }

    _calculateCameraPosOnHit(mesh, cameraPos) {
        const dir = new THREE.Vector3()
        let posOnHit = null;

        this.raycaster.set(
            this.controls.target,
            dir.subVectors(cameraPos, this.controls.target).normalize()
        )

        if (mesh) {
            const collision = this.raycaster.intersectObjects([mesh]);
            if (collision[0]) {
                if (collision[0].distance < this.controls.target.distanceTo(cameraPos)) {
                    const offset = collision[0].face.normal.clone().multiplyScalar(0.15);
                    posOnHit = collision[0].point.clone().add(offset);
                }
            }
        }
        return posOnHit;
    }

    _calculateGroundLevel(navmesh) {
        const playerMesh = this.player.GetMesh();
        const playerOrigin = new THREE.Vector3().copy(playerMesh.position);

        var forward = new THREE.Vector3(0, 0, -0.35 * this._input.current.y);
        forward.applyQuaternion(playerMesh.quaternion);

        const posOffset = new THREE.Vector3(playerOrigin.x + forward.x, playerOrigin.y + 1, playerOrigin.z + forward.z);
        this.raycaster.set(posOffset, new THREE.Vector3(0, -1, 0), 0, 1);

        let groundLevel = null;

        if (navmesh) {
            const collision = this.raycaster.intersectObjects([navmesh]);
            if (collision[0]) {
                if (collision[0].distance < 1.35) {
                    groundLevel = posOffset.y - collision[0].distance;
                }
            }
        }
        return groundLevel;
    }

    _calculateDeltaTime() {
        const deltatime = this.clock.getDelta();
        this._deltaTime = deltatime;
    }

    _onCountdownUpdated(countdown) {        //TODO Remove DEBUG
        console.log(countdown)
    }

    StopScene() {
        cancelAnimationFrame(this.animationFrame);
        clearInterval(this.checkObjectsInterval);
        analytics.EndMetalitixSession();
    }

    Remove(callback) {
        this.StopScene();
        this.container.removeChild(this.renderer.domElement);
        window.removeEventListener('resize', this.resizeListener, false);
        this.joystick.remove();
        this.keyboard.remove();
        this.renderer = null;
        this.camera = null;
        this.scene = null;
        if (callback) callback();
    }

    _playPortalAnimation(animationName) {
        this.portal.currentAnimation = animationName;
        const clip = THREE.AnimationClip.findByName(this.portal.allAnimations, animationName);

        if (!clip) return;
        clip.optimize();
        if (this.portal.action) this.portal.action.fadeOut(0.2);
        this.portal.action = this.portal.mixer.clipAction(clip);
        this.portal.action.reset();
        this.portal.action.fadeIn(0.2);
        this.portal.action.play();
    }
}