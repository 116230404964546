import * as THREE from 'three';
import anims from '@/json/animations.json'


export class Dummy{
    _character;
    _mesh;
    _animations;
    _mixer;

    _currentAnimation = 0;

    constructor(character) {
        this._character = character;
        this._mesh = character.scene;
        this._animations = character.animations;
        this._mixer = new THREE.AnimationMixer(this._mesh);
        this._emoji = null;

        this._playAnimation(0);
    }

    GetMesh() {
        return this._mesh;
    }

    GetCurrentAnimation() {
        return this._currentAnimation;
    }

    Update(playerData, level) {
        if (playerData[8] != level) {
            if (this._mesh.visible) {
                this._mesh.visible = false;
                return;
            }
        } else {
            if (!this._mesh.visible) {
                this._mesh.visible = true;
            }
        }

        this._mesh.position.set(playerData[0], playerData[1], playerData[2]);
        this._mesh.rotation.set(playerData[3], playerData[4], playerData[5]);
        this._manageAnimations(playerData[7]);
    }

    UpdateAnim(deltaTime) {
        if (this._mesh.visible) {
            this._mixer.update(deltaTime);
        }
    }

    _manageAnimations(animation) {
        if (animation == this._currentAnimation) return;

        this._playAnimation(animation);
    }

    _playAnimation(animationIndex) {
        const animationName = anims[animationIndex];
        this._currentAnimation = animationIndex;
        const clip = THREE.AnimationClip.findByName(this._animations, animationName);

        if (!clip) return;

        clip.optimize();
        if (this._action) this._action.fadeOut(0.2);
        this._action = this._mixer.clipAction(clip);
        this._action.reset();
        this._action.fadeIn(0.2);
        this._action.play();
    }

}