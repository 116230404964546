<template>
  <ThreejsCanvas ref="scene" />
  <div class="frontOfOverlay">
    <SplashScreen v-show="displaySplash" />
  </div>
</template>

<script setup>
window.location.replace("https://www.axe.com/us/en/home.html");
import ThreejsCanvas from "@/components/ThreejsCanvas.vue";
import SplashScreen from "@/components/SplashScreen.vue";

// export default {
//         name: 'App',
//         components: {
//             ThreejsCanvas,
//             SplashScreen
//         },
//         data() {
//             return {
//                 displaySplash: true,
//             }
//         },
//         methods: {
//             SwitchToScene(avatarIndex) {
//                 this.displaySplash = false;
//                 this.$refs.scene.LaunchScene(avatarIndex);
//             }
//         }
// }
</script>

<style>
@import "./scss/styles.scss";
</style>
