import { wsMessage } from "@/class/websocket/wsMessage";

export class CommunicationController {
    constructor(client, sceneEvents) {
        this.client = client;
        this.sceneEvents = sceneEvents;

        this.sceneEvents.onPlayerTransformChanged = (playerData) => {
            this.SendPlayerData(playerData)
        };
    }

    OnMessageReceived(json) {
        const message = new wsMessage(json.m, json.d);
        const messageType = message.m;
        const data = message.d;

        switch (messageType) {
            case wsMessage.messageType.Authentication:
                break;
            case wsMessage.messageType.Ping:
                break;
            case wsMessage.messageType.Update:
                this._onUpdateMessage(data);
                break;
            case wsMessage.messageType.PlayerConnected:
                this._onPlayerConnected(data);
                break;
            case wsMessage.messageType.PlayerDisconnected:
                this._onPlayerDisconnected(data);
                break;
            case wsMessage.messageType.Countdown:
                this._onCountdownUpdated(data);
                break;
            case wsMessage.messageType.Emoji:
                this._onEmojiTriggered(data);
                break;
            default:
        }
    }

    _onCountdownUpdated(countdown) {
        const onCountdownUpdated = this.sceneEvents.onCountdownUpdated
        if (onCountdownUpdated == null) return;
        onCountdownUpdated(countdown);
    }

    _onPlayerDisconnected(playerId) {
        const onPlayerDisconnected = this.sceneEvents.onPlayerDisconnected
        if (onPlayerDisconnected == null) return;
        onPlayerDisconnected(playerId);
    }


    _onPlayerConnected(data) {
        const onPlayerConnected = this.sceneEvents.onPlayerConnected
        if (onPlayerConnected == null) return;
        onPlayerConnected(data);
    }

    _onUpdateMessage(data) {
        const onUpdateFromServerFunc = this.sceneEvents.onUpdateFromServer
        if (onUpdateFromServerFunc == null) return;
        onUpdateFromServerFunc(data);
    }

    _onEmojiTriggered(emojiData) {
        const onEmojiTriggered = this.sceneEvents.onEmojiTriggered;
        if (onEmojiTriggered == null) return;
        onEmojiTriggered(emojiData);
    }

    SendPlayerData(playerData) {
        this.debug = playerData;
        if (this.client) {
            const message = wsMessage.playerDataMsg(playerData);
            this.client.SendJSON(message)
        }
    }

    TriggerEmoji(emoji) {
        if (this.client) {
            const message = wsMessage.triggerEmojiMsg(emoji);
            this.client.SendJSON(message)
        }
    }

    TerminateSession() {
        if (this.client) {
            const message = wsMessage.terminateMsg();
            this.client.SendJSON(message)
        }
    }
}