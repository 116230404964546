import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js';

export class ModelsController {
    static modelsPath = '/assets/models';
    static models = {
        animatedM: "rigs/male/male_avatar.gltf",
        animatedF: "rigs/female/female_avatar.gltf",
    }

    GLTFLoader = null;

    constructor() {
        this.GLTFLoader = new GLTFLoader();
        this.loadedModels = {};
        this.skeletons = {};

        this.link = document.createElement('a');
        this.link.style.display = 'none';
        document.body.appendChild(this.link); // Firefox workaround, see #6594
    }

    async Init() {
        return new Promise((resolve) => {
            const animatedM = this.LoadModel(ModelsController.models.animatedM);
            const animatedF = this.LoadModel(ModelsController.models.animatedF);

            Promise.all([animatedM, animatedF]).then((results) => {
                this.skeletons.m = results[0];
                this.skeletons.f = results[1];
                resolve(this);
            })
        })
    }

    async LoadModel(modelName) {
        return new Promise((resolve, reject) => {
            const path = `${ModelsController.modelsPath}/${modelName}`;
            this.GLTFLoader.load(path,
                (gltf) => {
                    resolve(gltf);
                }, function (xhr) {
                    console.log((xhr.loaded / xhr.total * 100) + '% loaded');      //TODO progress is not working
                }, (e) => {
                    reject(e);
                }
            );
        });
    }

    ExportModel(scene) {
        const exporter = new GLTFExporter();
        const options = {
            trs: false,
            onlyVisible: true,
            binary: true,
            maxTextureSize: 1024
        };

        // Parse the input and generate the glTF output
        exporter.parse(
            scene,
            (result) => {  // Changed this to an arrow function
    
                if (result instanceof ArrayBuffer) {
    
                    this.saveArrayBuffer(result, 'scene.glb');
    
                } else {
    
                    const output = JSON.stringify(result, null, 2);
                    console.log(output);
                    this.saveString(output, 'scene.gltf');
    
                }
    
            },
            (error) => {  // Changed this to an arrow function
    
                console.log('An error happened during parsing', error);
    
            },
            options
        );
    }

    save(blob, filename) {
        this.link.href = URL.createObjectURL(blob);
        this.link.download = filename;
        this.link.click();
    }

    saveString(text, filename) {
        this.save(new Blob([text], { type: 'text/plain' }), filename);
    }

    saveArrayBuffer(buffer, filename) {
        this.save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
    }
}